.main-container {
  margin: 0;
  padding: 0 0 20px;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  text-decoration-line: none;
  font-family: "Inter", Helvetica, sans-serif;
  height: 100vh;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 1.5;
}

.protocol_box {
  width: 97%;
  margin: 20px auto;
  padding: 0 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #fbfbfb;
}

.protocol_box-edit {
  width: 97%;
  margin: 20px auto;
  padding: 0 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #ffffff;
}

.protocol__form {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  padding: 20px 0;
  margin-bottom: 20px;
}

.protocol__complect {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.protocol__complect-top {
  width: 100%;
  height: 49px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.protocol__left {
  display: flex;
  justify-content: flex-start;
  margin-right: auto;
}

.protocol__left span {
  font-weight: 600;
  margin-left: 5px;
}

.protocol__right {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  cursor: pointer;
}

.protocol__complect-bottom {
  width: 100%;
  box-sizing: border-box;
  margin: 5px auto;
}

.protocol__complect-bottom p {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-top: 10px;
  padding-left: 15px;
  margin-top: 5px;
}

.protocol__complect-bottom span {
  font-size: 15px;
  font-weight: 500;
}

/* ---------------------------------------- */

.protocol__counter {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #fbfbfb;
  padding-bottom: 10px;
  padding-top: 10px;
}

.protocol__counter-text p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.protocol__counter-items {
  display: flex;
  align-items: flex-start;
}

.protocol__counter-paragraph {
  display: flex;
  margin-right: 10px;
  flex-direction: column;
  width: 38%;
}
.protocol__counter-paragraph-year {
  display: flex;
  margin-right: 10px;
  flex-direction: column;
  width: 10%;
}
.protocol__counter-paragraph-water {
  display: flex;
  width: 13%;
  flex-direction: column;
}

.protocol__counter-paragraph-items {
  display: flex;
  flex-direction: column;
}

.protocol__counter-paragraph span {
  padding-right: 10px;
}
.count {
  display: flex;
}

.protocol__counter-paragraph-items input {
  width: 100%;
  margin-right: 10px;
  width: 170px;
  height: 35px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  padding-left: 10px;
  border-radius: 5px;
  outline: none;
}

.search {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 5px;
  cursor: pointer;
  background: #ffffff;
  overflow-x: auto;
  padding: 0 10px;
}

.search p {
  padding-left: 10px;
  white-space: nowrap;
}
.search input {
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  overflow-x: scroll;
  padding: 0;
}

.search-year {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
  padding: 0 9px;
}

.fif_down {
  width: 100%;
  height: 90px;
  margin-top: 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 5px;
  background: #ffffff;
  padding: 5px 0;
  overflow-y: scroll;
}

.fif_down p {
  margin-bottom: 5px;
  cursor: pointer;
  padding-left: 10px;
}
.fif_down p:hover {
  color: #009ef7;
}
.active-1 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  background: #fbfbfb;
  color: #009ef7;
  padding-left: 10px;
}

.protocol__counter-paragraph-items-water {
  display: flex;
  flex-direction: column;
  height: 33px;
}
.protocol__counter-paragraph-items-water p {
  color: #161616;
  font-weight: 300;
  font-size: 17px;
  text-align: center;
  justify-content: center;
}
.search-water-cold {
  background-color: #009ef7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  cursor: pointer;
}
.search-water-cold p {
  color: #ffffff;
}

.search-water-hot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3063e;

  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  cursor: pointer;
}
.search-water-hot p {
  color: #ffffff;
}

.change {
  display: none;
}
.change__text {
  width: 98%;
  height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(217, 219, 228, 0.6);
  padding-left: 10px;
  border-radius: 5px;
}
.change__text span {
  font-weight: 600;
}
.protocol__counter-paragraph-items-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.protocol__counter-paragraph-items-bottom p {
  font-size: 17px;
  font-weight: 500;
}

.protocol__counter-paragraph-items-bottom-input {
  width: 600px;
  height: 35px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  padding-left: 10px;
  border-radius: 5px;
  outline: none;
}

@media (max-width: 768px) {
  .protocol__counter {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
  .protocol__counter-text {
    display: flex;

    justify-content: center;
    margin-bottom: 10px;
  }

  .protocol__counter-paragraph {
    width: 100%;
  }

  .protocol__counter-items {
    display: flex;
    flex-direction: column;
  }

  .search {
    width: 100%;
    background: #fff;
  }

  .protocol__counter-paragraph-items input {
    width: 100%;
  }

  .protocol__counter-paragraph-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    background-color: #ffffff;
  }

  .protocol__counter-paragraph-items-water {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    text-align: center;
  }

  .search-water-cold {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-water-hot {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .protocol__counter-paragraph-items-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .protocol__counter-paragraph-items-bottom p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
  }

  .protocol__counter-paragraph-items-bottom-input {
    width: 100%;
    margin: 0 auto 10px;
  }

  .change__text {
    text-align: center;
    margin-bottom: 10px;
  }
}

// Protocol Protocol ФИО
.name {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
}

.name__box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-left: 20px;
}

.name input {
  width: 260px;
  height: 40px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 5px;
  outline: none;
}

// Protocol Адрес
.address {
  width: 100%;
  box-sizing: border-box;
  margin: 10px auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 5px;

  &__text {
    display: flex;
    flex-direction: column;
  }
  &__input input {
    width: 610px;
    height: 40px;
    border: 1px solid rgba(217, 219, 228, 0.6);
    padding-left: 10px;
    border-radius: 5px;
    outline: none;
  }

  &__suggestions {
    width: 100%;
    height: 100%;
    margin-top: 10px;

    border: 1px solid rgba(217, 219, 228, 0.6);
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__suggestions p {
    width: 100%;
    cursor: pointer;
  }

  &__suggestions p:hover {
    color: #009ef7;
  }
}

@media (max-width: 768px) {
  .name,
  .address {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .name__text,
  .address__items {
    margin-bottom: 5px;
  }

  .name__box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .name__box-name {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .name__box-name input {
    width: 100%;
  }

  .address__text {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .address__input {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;
  }

  .address__input input {
    width: 100%;
  }
}

// Protocol Проверка с боксом "галочка"
.inspection_main {
  background: #e3063e;
  display: flex;
  flex-direction: column;
}
.inspection {
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-left: 10px;
}
.inspection-edit {
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-left: 10px;
}

.inspection__text {
  width: 100%;

  margin: 0 0 5px 0;
}

.inspection__text h2 {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  margin-bottom: 8px;
  margin-top: 8px;
}
.inspection__text p {
  width: 100%;
  display: flex;
  padding-left: 0;

  text-align: left;
}
.inspection__items label {
  width: 100%;
  display: flex;
  align-items: center;
}

.inspection__items label p {
  width: 100%;
}
.inspection__items input {
  width: 20px;
  border: 1px solid red;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.inspection__items-non {
  color: #e3063e;
  width: 100%;
  padding-bottom: 10px;
}

// Protocol Блок Показания
.testimony_box1 {
  width: 100%;
  margin-top: 10px;
}
.testimony {
  width: 100%;
  box-sizing: border-box;
  display: flex;
}
.testimony__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #fbfbfb;
  padding: 10px 15px;

  .testimony__box-consumption span {
    margin: 0 5px;
  }

  .testimony__box-items {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .testimony__box-items-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }

  .testimony__box-start p,
  .testimony__box-finish p {
    width: 100%;
  }

  .testimony__box-items-bottom_box {
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(217, 219, 228, 0.6);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 5px auto 0;
    padding: 10px;
  }
  .testimony__box-items-bottom_box p {
    width: 100%;
    font-size: 14px;
    text-align: right;
  }

  .testimony__box-items-bottom_box span {
    margin: 0 5px;
  }

  .testimony__box-items span,
  .testimony__box-volume span {
    font-size: 20px;
    font-weight: 500;
    color: #2897bf;
  }

  .testimony__box-volume {
    padding-top: 10px;
  }

  .testimony__box-volume p {
    width: 100%;
  }

  .testimony__box-volume span {
    margin: 0 5px;
  }
  .testimony__box-start p,
  .testimony__box-finish p {
    font-weight: 600;
  }

  .testimony__box-start input,
  .testimony__box-finish input {
    width: 280px;
    height: 35px;
    margin: 5px 0;
    border: 1px solid rgba(217, 219, 228, 0.6);
    padding-left: 10px;
    border-radius: 5px;
    outline: none;
  }
}

@media (max-width: 1450px) {
  .testimony__box-start input,
  .testimony__box-finish input {
    width: 600px;
    height: 40px;
    border: 1px solid rgba(217, 219, 228, 0.6);
    padding-left: 10px;
    border-radius: 5px;
    outline: none;
  }
}

@media (max-width: 1100px) {
  .testimony h2 {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .testimony__box-items {
    display: flex;
    flex-direction: column;
  }
  .testimony__box-items input {
    margin-bottom: 10px;
  }

  .testimony__box-consumption p {
    margin-bottom: 10px;
  }
}

// Protocol С условия проверки

.examination {
  width: 100%;
  box-sizing: border-box;
}
.examination__text {
  display: flex;
  padding-left: 10px;
  margin-bottom: 10px;
}

.examination__box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.examination__box-left,
.examination__box-right {
  width: 48%;
  border-radius: 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  padding-top: 5px;
}
.examination__box-left h4,
.examination__box-right h4 {
  font-size: 18px;
  font-weight: 500;
  margin-left: 24px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.examination__box-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .examination__box-items-text {
    width: 90%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
}

.examination__box-items-text p {
  width: 320px;
  font-size: 13px;
  font-weight: 300;
}

.examination__box-items-text input {
  margin-left: auto;
  width: 100%;
  height: 30px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  padding-left: 10px;
  border-radius: 5px;
  outline: none;
}

@media (min-width: 1880px) and (max-width: 2000px) {
  .examination__box {
    display: flex;
  }
  .examination__box-items-text input {
    margin-left: auto;
    width: 220%;
    height: 30px;
    border: 1px solid rgba(217, 219, 228, 0.6);
    padding-left: 10px;
    border-radius: 5px;
    outline: none;
  }
}
@media (max-width: 768px) {
  .examination__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .examination__box-left,
  .examination__box-right {
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(217, 219, 228, 0.6);
    background-color: #fbfbfb;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .examination__box-left h4,
  .examination__box-right h4 {
    font-size: 15px;
    font-weight: 500;
    padding-top: 10px;
  }
}

@media (min-width: 1724px) and (max-width: 1879px) {
  .examination__box-items-text input {
    margin-left: auto;
    width: 180%;
    height: 30px;
    border: 1px solid rgba(217, 219, 228, 0.6);
    padding-left: 10px;
    border-radius: 5px;
    outline: none;
  }
}

@media (min-width: 1600px) and (max-width: 1723px) {
  .examination__box-items-text input {
    margin-left: auto;
    width: 150%;
    height: 30px;
    border: 1px solid rgba(217, 219, 228, 0.6);
    padding-left: 10px;
    border-radius: 5px;
    outline: none;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .examination__box-items-text input {
    margin-left: auto;
    width: 110%;
    height: 30px;
    border: 1px solid rgba(217, 219, 228, 0.6);
    padding-left: 15px;
    border-radius: 5px;
    outline: none;
  }
}

// Protocol Блок с Датой проверки
.form__footer {
  width: 100%;
  padding: 0 10px;
  display: flex;

  justify-content: space-between;
}
.form__footer-items {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  height: 40px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  padding-left: 10px;
  border-radius: 5px;
}

.next_date_protocol {
  background-color: #ffffff;
  color: #000;
}

.calendar input {
  border: none;
  outline: none;
  margin-right: 50px;
}
.form__footer-items p {
  font-size: 15px;
  margin-right: 10px;
}

@media (max-width: 879px) {
  .form__footer {
    width: 100%;
    margin: 0;
    padding: 5px;
    display: flex;
    flex-direction: column;
  }

  .form__footer-items {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .form__footer-items > div {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .form__footer-items p,
  .form__footer-items input {
    width: 100%;
    margin: 0;
  }
}

.btn_main {
  margin-top: 20px;
  width: calc(1.5rem * 2 + 130px);
  display: inline-block;
  padding: 0.775rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0.65rem;
  background-color: #50cd89;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn_main:hover {
  background-color: #25cb70;
}

.btn-edit {
  display: flex;
  justify-content: center;
}

.pagination {
  width: 100%;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active_paginate {
  background-color: #25cb70;
}

.pagination button {
  width: 30px;
  height: 30px;
  margin: 0 3px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 3px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #ffffff;
}

.loading-text-container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 500;
  color: #b6b6b6;
}

.loading-dot-1,
.loading-dot-2,
.loading-dot-3 {
  animation: blink 1s infinite;
}

.loading-dot-1 {
  animation-delay: 0.2s;
}

.loading-dot-2 {
  animation-delay: 0.4s;
}

.loading-dot-3 {
  animation-delay: 0.6s;
}

.edit_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
