* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  list-style-type: none;
}
body {
  font-family: "Inter", sans-serif;
  list-style-type: none;
}

/* **************LOGIN************ */

/* Общий контейнер */

/* Контейнер формы */
.wrapper_login {
  margin: 90px auto;
  width: 400px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  background: #fff;
  width: 600px;
  max-height: 100%;
  margin: 20px auto;
  border-radius: 10px;
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Контейнер внутри */
.container__box {
  max-width: 80%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .container {
    width: 90%;
  }
}

/* Адаптивность для планшетов */
@media (max-width: 1024px) {
  .container {
    width: 70%;
  }
}

/* Контент формы */
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #333;
  margin-right: 10px;
}
.user_title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-icon {
  margin-left: 5px;
}

.title p {
  font-size: 16px;
  color: #888888;
  margin-left: 40px;
}

.form {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.form__login input {
  width: 96%;
  height: 40px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #888888;
  background: #fff;
}
.form__login input:focus {
  outline: none;
}
.form__footer {
  display: flex;
  justify-content: end;
  font-size: 15px;
  font-weight: 300;
  color: rgb(34, 139, 34);
}
.form__footer a {
  text-decoration: none;
  color: rgb(34, 139, 34);
}
.form__footer a:hover {
  color: rgb(24, 119, 24);
}

/* Кнопка */
.login-button {
  width: 150px;
  height: 40px;
  margin-top: 20px;
  margin-left: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #2ecc71;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-icon {
  margin-right: 10px;
}
.error-message {
  color: red;
  margin-top: 10px;
}

.login-button:hover {
  background-color: #27ae60;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .form__login input {
    width: 100%;
  }
}

/* Адаптивность для планшетов */
@media (max-width: 1024px) {
  .title h2 {
    font-size: 20px;
  }
}

/* Кнопка */
.btn {
  width: 100%;
  height: 40px;
  border: 1px solid rgb(34, 139, 34);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background: rgb(34, 139, 34);
  cursor: pointer;
}

.btn:hover {
  background: rgb(24, 119, 24);
}

.footer__title {
  color: #888888;
}
.footer__title a {
  text-decoration: none;
  color: rgb(34, 139, 34);
}
/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .btn {
    height: 30px;
    font-size: 14px;
  }
}

/* Адаптивность для планшетов */
@media (max-width: 1024px) {
  .btn {
    height: 35px;
    font-size: 15px;
  }
}

/* ************HOME************* */
.container__home {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
}

/* Левая сторона */
.container__left {
  width: 250px;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background: #111424;
  border-right: 1px solid #1c2039;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo {
  margin: 30px auto;
}

.header__left {
  display: inline-block;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px auto;
}

.header__left h2 {
  width: 100%;
  height: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}

.active {
  background: #50cd89;
  width: 100%;

  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #000000;
  font-weight: 500;
}

.active-users {
  color: #25cb70;
  cursor: pointer;
}

.home__footer-left {
  line-height: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}
.home__footer-left-title h2 {
  font-size: 15px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.home__footer_left_exit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  margin-left: 80px;
  margin-bottom: 20px;
  cursor: pointer;
  color: #fff;
}
.logout {
  margin-left: 10px;
}
.home__footer_left_exit:hover {
  color: #808080;
}

/* Правая сторона */
.container__right {
  width: calc(100% - 250px);
  position: fixed;
  height: 100%;
  overflow-y: auto;
  margin-left: 250px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  background: white;
  color: black;
  box-sizing: border-box;
}

.header {
  width: 95%;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 10px;
}
.header__left {
  display: flex;
  align-items: center;
}

.create-button {
  width: 150px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #47be7d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-button:hover {
  background-color: #2cbd6d;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.create-icon {
  margin-right: 8px;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  width: 200px;
  height: 34px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.search-button {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #2897bf;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #0056b3;
}

.search-icon {
  color: #fff;
}

/* Левая сторона */

/* main */
.main {
  margin-top: 20px;
  max-height: calc(100vh - 90%);
}

.main__container {
  height: 100%;
  margin: 0 30px 20px 20px;
  padding-bottom: 20px;
  display: flex;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1), 0px 3px 20px rgba(0, 0, 0, 0.1);
}

.save__users-2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}

.save__users-3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cards__right {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.main__title {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
}

.main__title h3 {
  font-size: 20px;
  font-weight: 500;
}
.main__title span {
  color: #808080;
  font-size: 22px;
  font-weight: 600;
}

.main__card {
  width: 800px;
  height: 30px;
  display: flex;
  align-items: center;
}

.main__label {
  width: 300px;
  height: 26px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #d3d3d3;
  font-size: 14px;
}
.main__label p {
  width: 250px;
}

.main__value {
  width: 350px;
  height: 26px;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #d3d3d3;
  color: #5a5a5a;
}
.main__value p {
  width: 600px;
  font-size: 14px;
  color: #5a5a5a;
}

.card__left {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.card__left button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  background-color: #ff9999;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card__left button:hover {
  background-color: #fb8686;
}
.main__container {
  height: 100%;
  margin: 0 30px 20px 20px;
  padding-bottom: 20px;
  display: flex;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1), 0px 3px 20px rgba(0, 0, 0, 0.1);
}

.show-icon {
  margin-right: 8px;
}

.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c82333;
}

.delete-icon {
  margin-right: 15px;
}

.card__status {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 10px;
}

.card__status h5 {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
}

.show {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.show button {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  background-color: #007bff;
  color: #fff;

  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.show button:hover {
  background-color: #0056b3;
}

.edit-icon {
  margin-right: 8px;
}

.edit-button {
  margin-top: 60px;
  margin-bottom: 10px;
}

.new {
  height: 100%;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.new_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.new_title {
  font-size: 18px;
  font-weight: bold;
}

.new_btn {
  height: 40px;
  display: flex;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.new_btn:hover {
  background-color: #0056b3;
}

.save-icon {
  margin-right: 8px;
}

.new_box {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content_box {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
}

p {
  width: 210px;
  display: flex;
  justify-content: left;
  margin: 0;
  font-weight: 400;
  color: #000000;
}

input {
  width: 200px;
  height: 34px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 10px;
  outline: none;
}

.paginate {
  list-style-type: none;
  margin-right: 35px;
  margin-top: 30px;
}
.paginate li {
  display: inline-block;
  color: #fff;
}

.paginate a {
  display: inline-block;
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 32px;
  color: #fe5f1e;
  border: 1px solid #fe5f1e;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.paginate a:hover {
  background-color: #fe5f1e;
  color: #fff;
}

.paginate li.selected {
  display: inline-block;
  background-color: #f3b8a1;
  color: #fff;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.add {
  margin-top: 20px;
  width: 100%;
}

.add_1 {
  width: 96%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 10px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.add_1 h2,
.add_1 h3 {
  font-size: 20px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.55);
}
.add__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.add_2 {
  width: 700px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.add_2 label {
  width: 200px;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 17px;
  font-weight: 400;
  font-size: 14px;
}

.add_2 input {
  width: 380px;
  height: 35px;
  background-color: #f8f8f8;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  color: #333;
}

.selected_meter input {
  width: 380px;
  height: 35px;
  background-color: #f8f8f8;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  color: #333;
  margin-bottom: 10px;
  margin-left: 0;
}

.selected {
  display: flex;
  align-items: flex-start;
}
.selected_meter {
  display: flex;
  align-items: flex-start;
}
.selected_meter h5 {
  height: 35px;
  margin: 0;
  padding-top: 5px;
  font-size: 14px;
}
.selected_meter label {
  width: 230px;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 17px;
  font-weight: 400;
  font-size: 14px;
}

.selected label {
  width: 210px;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 17px;
  font-weight: 400;
  font-size: 14px;
}
.selected_box {
  display: flex;
  flex-direction: column;
}
.selected_users_top {
  width: 380px;
  height: 100%;
  margin-bottom: 10px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  padding: 10px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
}

.selected_meter_top {
  width: 380px;
  height: 35px;
  margin-bottom: 10px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  padding: 0 10px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
}

.selected_users_bottom,
.selected_meter_bottom {
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  width: 380px;
  background-color: #f8f8f8;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  overflow-y: scroll;
}

.selected_users_bottom-role {
  width: 100%;
  height: 90px;
  margin-bottom: 10px;
  width: 380px;
  background-color: #f8f8f8;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  overflow-y: scroll;
}

.selected_meter_bottom_water {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  width: 380px;
  background-color: #f8f8f8;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.selected_users_bottom p,
.selected_meter_bottom p,
.selected_meter_bottom_water p,
.selected_users_bottom-role p {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0 0 5px;
}
.selected_users_bottom p:hover,
.selected_meter_bottom p:hover,
.selected_meter_bottom_water p:hover,
.selected_users_bottom-role p:hover {
  color: #2884ef;
}

.selected_users_bottom_cases {
  margin-bottom: 10px;
  width: 100%;
  height: 100px;
  background-color: #f8f8f8;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  overflow-y: scroll;
}

.selected_box_counter {
  background-color: #f8f8f8;
  width: 380px;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  border: none;
  border-radius: 4px;
}

.btn_selected_box_counter {
  border: none;
  background-color: #f8f8f8;
  font-size: 25px;
  cursor: pointer;
  color: #5a5a5a;
}
.btn_selected_box_counter:hover {
  color: #2897bf;
}

.selected_users_bottom_cases p {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.selected_users_bottom_cases p:hover {
  color: #2884ef;
}
.btn_meters {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_cases {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-cases {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 20px;
  background: #007bff;
}

.button-cases p {
  color: #ffffff;
  font-size: 20px;
}

.button {
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;

  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 20px;
  margin-right: 30px;
  background: #007bff;
}

.button-icon {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  margin-left: 20px;
}

.button-icon-11 {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  margin-left: 10px;
}

.button p {
  color: white;
  font-size: 15px;
}

.button:hover {
  background: #0056b3;
}

.confirmation-modal {
  background-color: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 200px;
  justify-content: center;

  position: relative;
}

.confirmation-modal__content {
  width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.confirmation-modal__content {
  font-size: 17px;
}

.confirmation-modal__buttons button {
  cursor: pointer;
  margin: 10px;
  position: relative;
  padding: 10px 18px;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  background: rgba(34, 34, 34, 0.1);
  border: 1px solid rgba(34, 34, 34, 0.1);
  border-radius: 5px;
  transition: 0.4s;
}

.confirmation-modal__buttons button:hover {
  background: #27ae60;
}

.error-message {
  width: 90%;
  margin: 0 auto;
  font-size: 11px;
  padding: 10px;
}

.down_btn {
  display: flex;
  margin-top: 20px;
}
.down_btn button {
  margin-right: 20px;
  background-color: #fb8686;
}

.down_btn button:hover {
  background-color: #f75f5f;
}

.kits h4 {
  background-color: #50cd89;
  color: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 16px;
}

.add_1 h3 {
  margin-bottom: 0;
}

.footer_btn {
  display: flex;
  margin-top: 10px;
}

.add__container h3 {
  margin-bottom: 20px;
}

.attend {
  margin-bottom: 90px;
}
.attendance {
  margin-top: 15px;
}

.attendance-list {
  width: 95%;
  padding: 10px;
  margin: 10px auto;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}
.attendance-list h2 {
  padding-left: 5px;
}

.table {
  border-collapse: collapse;
  margin: 15px 0;
  font-size: 12px;
  min-width: 100%;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
table thead tr {
  width: 100%;
  color: #4e4d4d;
  background: #f1f1f1;
  text-align: left;
  font-weight: 200;
  font-size: 12px;
}

.th {
  width: 40px;
  text-align: center;
}

.table th,
.table td {
  padding: 12px 8px;
}

.table tbody tr:hover {
  background-color: #d5f4e3;
}

.table tbody td {
  cursor: pointer;
}

.table p {
  margin-right: 10px;
}

.table tbody tr.active {
  font-weight: 300;
  color: #fbfbfb;
  color: #000000;
}
.table tbody tr:last-of-type {
  border-bottom: 2px solid #25cb70;
}

.table span {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-icon:hover,
.download-icon:hover,
.archive-icon:hover {
  color: #0399f0;
}

@media (max-width: 1236px) {
  .attendance-list {
    overflow-x: auto;
  }
}

.cases_container {
  width: 95%;
  height: 100%;
  padding: 20px 5px;
  margin: 0 auto;
  display: flex;
}
.cases_container_tab1 {
  width: 10%;
}

.cases_container_tab2 {
  width: 25%;
}
.cases_container_tab3 {
  width: 15%;
}
.cases_container_tab4 {
  width: 35%;
}
.cases_container_tab5 {
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.custom-file-input {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.custom-file-input input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.custom-file-input span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  font-size: 13px;
  cursor: pointer;
}

.selected-photo {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.cases_container_title {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(217, 219, 228, 0.6);
}
.cases_container_title p {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cases_container_desc {
  width: 100%;
  height: 320px;
  padding: 5px;
  border: 1px solid rgba(217, 219, 228, 0.6);
}

.cases_container_desc-edit {
  width: 100%;
  height: 390px;
  padding: 5px;
  border: 1px solid rgba(217, 219, 228, 0.6);
}
.cases_container_desc-vol {
  width: 100%;
  height: 35px;
  font-size: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(217, 219, 228, 0.6);
}

.cases_container_desc-vol-edit {
  width: 100%;
  height: 35px;
  font-size: 13px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(217, 219, 228, 0.6);
}

.cases_container_desc-vol-1 {
  width: 100%;
  height: 105px;
  font-size: 13px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(217, 219, 228, 0.6);
}

.cases_container_desc-vol-name {
  width: 100%;
  height: 105px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(217, 219, 228, 0.6);
}

.deviceType {
  width: 100%;
  display: flex;
}

.deviceType input.cases_container_desc-deviceType {
  width: 70%;
  height: 35px;
}

.deviceType input.cases_container_desc-deviceSign {
  width: 30%;
}

.cases_container_desc-name {
  width: 100%;
  height: 50px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cases_container_desc-link {
  display: flex;
  flex-direction: column;
}

.cases_container_desc input,
.cases_container_desc-edit input {
  width: 100%;
  margin: 0;
  border: none;
  border-bottom: 1px solid rgba(217, 219, 228, 0.6);
  outline: none;
}

.fif_reg_number {
  width: 20%;
  height: 60px;
  display: flex;
}
.reg_num_1 {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  padding-left: 3px;
}

.reg_num_2 {
  display: flex;
  align-items: center;
  width: 200px;
  font-size: 14px;
}

.reg_num_3 {
  width: 40px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.fif_reg_number1 {
  width: 20%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 0 5px;
}

.cases_container_box-2 {
  display: flex;
  align-items: center;
  background: #fff;

  border-radius: 5px;
  margin: 10px;
}

.cases_container_box-num {
  width: 3%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;
}
.cases_container_box-num-1,
.cases_container_box-num-2 {
  width: 20%;
  height: 60px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
  padding-left: 5px;
}

.cases_container_box-name {
  width: 20%;
  height: 60px;
  display: flex;

  align-items: center;
  margin-right: 10px;
  padding: 0 8px;
  font-size: 14px;
}

.cases_container_box-name span {
  margin: 0 10px;
  font-weight: bold;
  font-size: 20px;
}

.cases_container_box-fif {
  width: 20%;
  height: 60px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 15px;
}

.cases_container_box-desc {
  width: 60%;
  height: 60px;
  display: flex;
  justify-content: center;

  flex-direction: column;
}

.cases_container_box-desc p {
  width: 100%;
  font-size: 14px;
  margin-bottom: 4px;
}

.cases_delete-btn {
  width: 85%;
  height: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  background: #c7c7c7;
  color: #ffffff;
  cursor: pointer;
}

.cases_delete-btn:hover {
  background: #959494;
}

.cases-btn {
  width: 50%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-btn {
  margin-right: 20px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background: #25cb70;
  color: #ffffff;
  cursor: pointer;
}

.add-btn:hover {
  background: #0f9049;
}

.categories {
  width: 60%;
  height: 60px;
  margin: 0 auto;
  background-color: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
}

.categories button {
  width: 40%;
  height: 40px;
  display: flex;
  margin: 0 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #0399f0;
  border-radius: 10px;
  background: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.categories button:hover,
.categories button.categories-active {
  border: 1px solid #25cb70;
}

.meter__container {
  width: 100%;
  height: 100%;
}

.meter_box {
  width: 95%;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #fbfbfb;
  border-radius: 5px;
}
.meter_title_box {
  display: flex;
  align-items: center;
  height: 45px;
  margin-bottom: 10px;
  background: #ffffff;
  padding-left: 10px;
}
.meter_water {
  width: 220px;
  display: flex;
  margin-right: 20px;
}
.meter_title {
  width: 20%;
  font-size: 14px;
  font-weight: 400;
  color: #727272;
}

.meter_desc {
  width: 80%;
}

.meter_title-water {
  display: flex;
  align-items: center;

  width: 190px;
  font-size: 14px;
  font-weight: 400;
  color: #727272;
}

.meter_desc-water {
  width: 30px;
}

.users_tabl-tr {
  width: 16%;
  height: 60px;
}

/* Протокол */
.protocol_categories {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 3px solid #2897bf;
  background-color: #ffffff;
  border-radius: 5px 5px 0 0;
  padding-top: 20px;
  padding-bottom: 10px;
}

.protocol_categories-box {
  display: flex;
}

.protocol_calendar {
  width: 200px;
  height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 5px;
  margin-left: 10px;
  padding-left: 10px;
}

.protocol_calendar-input {
  width: 165px;
  height: 30px;
  border: none;
}

.protocol_categories-selected {
  width: 200px;
  height: 36px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 5px;
  background-color: #ffffff;
  justify-content: space-between;
  margin: 0 0 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.protocol_categories_top {
  width: 200px;
  height: 34px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  padding: 0 10px;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
}

.protocol_meters_top {
  width: 380px;
  height: 40px;
  margin-bottom: 10px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  padding: 0 10px;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
}

.protocol_categories_bottom {
  width: 200px;
  height: 90px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: #ffffff;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  overflow-y: scroll;
}

.protocol_categories_bottom-edit {
  width: 200px;
  height: 100%;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: #ffffff;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  overflow-y: scroll;
}

.protocol_categories_bottom p,
.protocol_meters_bottom p {
  font-size: 13px;
  margin-bottom: 3px;
}

.protocol_categories_bottom p:hover,
.protocol_meters_bottom p:hover {
  color: #086c90;
}

.protocol_meters_bottom {
  width: 380px;
  height: 100%;
  margin-bottom: 10px;
  background-color: #f8f8f8;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  overflow-y: scroll;
}

.protocol_categories-selected-bottom {
  width: 200px;
  height: 250px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #007bff;
  margin-top: 20px;
}

.protocol_categories-selected-top p {
  width: 180px;
  padding-left: 10px;
}

.protocol_categories-btn button {
  width: 150px;
  height: 34px;
  background: #2897bf;
  border-radius: 5px;
  border: none;
  margin: 0 10px;
  color: #ffffff;
  cursor: pointer;
}

.protocol_categories-btn button:hover {
  background-color: #086c90;
}

.excel {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #828282;
}

.excel-icon {
  width: 70%;
  height: 60%;
  cursor: pointer;
}

.excel-icon:hover {
  color: #086c90;
}

.protocol_comlplect {
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background: #ffffff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.protocol_adress-box {
  display: flex;
  flex-direction: column;
}

.protocol_adress-box-1 {
  width: 800px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #fbfbfb;
  border-radius: 5px;
  padding: 10px;
}

.protocol_adress-box-1 p {
  width: 100%;
}

.protocol_comlplect-p {
  width: 230px;
  color: #808080;
  font-size: 15px;
}
.protocol_comlplect-desc {
  color: #808080;
  font-size: 15px;
  margin-bottom: 5px;
}
.search-1 {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(217, 219, 228, 0.6);
  border-radius: 5px;
  background: #ffffff;
  padding: 0 10px;
  cursor: pointer;
}
.protocol_address {
  background-color: #ffffff;
}
.protocol_comlplect-input {
  width: 180px;
  margin: 10px 10px 10px 0;
}

.protocol_comlplect-input-1 {
  margin: 5px 0;
}

.protocol_comlplect-input-top {
  width: 60px;
  height: 27px;
}

.protocol_adress-input {
  width: 680px;
}

.protocol_adress-input input {
  width: 680px;
  margin: 0;
}

.protocol_address_bottom {
  width: 680px;
  height: 110px;
  padding: 0 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
}

.protocol_address_bottom p {
  font-size: 14px;
  cursor: pointer;
}

.protocol_address_bottom p:hover {
  color: #086c90;
}

.protocol_counter-p {
  width: 100%;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background: #fbfbfb;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 5px;
  font-size: 13px;
}

.protocol_counter,
.protocol_adress,
.protocol_testimony,
.protocol_examination-box,
.protocol-date {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background: #ffffff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.protocol_counter_complect {
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.protocol_counter_complect-box-1 {
  width: 30%;
  height: 35px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
}
.protocol_counter_complect-box-2 {
  width: 40%;
  height: 35px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
}
.protocol_counter_complect-box-1 input,
.protocol_counter_complect-box-2 input {
  width: 100%;
}

.protocol_counter_complect-box {
  width: 15%;
  height: 35px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.protocol_counter-num {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.protocol_counter-num input {
  width: 600px;
}
.protocol_adress-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.protocol_adress-name-box {
  display: flex;
}
.protocol_adress-p {
  width: 230px;
  color: #808080;
  font-size: 15px;
}

.protocol_adress-name-box-p {
  width: 180px;
  display: flex;
  margin-left: 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background: #fbfbfb;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 5px;
}
.protocol_adress-box {
  width: 700px;
  display: flex;
  border: none;
  background: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 5px;
}

.protocol_adress-box p {
  width: 100%;
}
.protocol_testimony-title {
  width: 100%;
  margin-bottom: 10px;
}
.protocol_testimony-box {
  display: flex;
  justify-content: space-between;
}

.protocol_testimony-inner-p {
  width: 30%;
  color: #808080;
  font-size: 15px;
}

.protocol_testimony-inner-p span {
  color: #2897bf;
  margin: 0 5px;
  font-size: 18px;
}

.protocol_testimony-inner-box {
  width: 30%;
}

.protocol_testimony-inner-box input {
  width: 100%;
}
.protocol_testimony-inner-box-p {
  color: #808080;
  font-size: 15px;
  width: 280px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.protocol_testimony-inner-box-desc {
  color: #808080;
  font-size: 15px;
  width: 280px;
  display: flex;
  align-items: center;
}

.protocol_examination-box-inner {
  display: flex;
  justify-content: space-between;
}

.protocol_examination-box-inner-left,
.protocol_examination-box-inner-right {
  width: 48%;
  display: flex;

  flex-direction: column;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background: #fbfbfb;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.box_inner-1 {
  display: flex;
  justify-content: space-between;
}

.box_inner-p {
  width: 100px;
  display: flex;
  margin-left: 10px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background: #fbfbfb;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 5px;
}
.protocol-date-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.protocol-date-box input {
  margin: 5px 0 0;
}

.protocol-date-box-title {
  color: #808080;
  font-size: 15px;
}

.water_bottom {
  margin-top: 10px;
  width: 180px;
  height: 60px;
  border: 1px solid rgba(217, 219, 228, 0.6);
  background: #ffffff;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.water_bottom p {
  font-size: 14px;
  margin-bottom: 4px;
}

.water_bottom p:hover {
  color: #086c90;
}

.loading-text-container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 500;
  color: #b6b6b6;
}

.loading-dot-1,
.loading-dot-2,
.loading-dot-3 {
  animation: blink 1s infinite;
}

.loading-dot-1 {
  animation-delay: 0.2s;
}

.loading-dot-2 {
  animation-delay: 0.4s;
}

.loading-dot-3 {
  animation-delay: 0.6s;
}
